$h1color: #6e768d;
$h3color: #b4bdc6;
$widthoftable: 50%;
$bgctables: #141414;
$bgfontcolor: #717787;

.pricingTable {
  margin: 40px auto;

  &>&-title {
    text-align: center;
    color: $h1color;
    font-size: 3em;
    font-size: 300%;
    margin-bottom: 20px;
    letter-spacing: 0.04em;
  }

  &>&-subtitle {
    text-align: center;
    color: $h3color;
    font-size: 1.8em;
    letter-spacing: 0.04em;
    margin-bottom: 60px;

    @media screen and (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &-firstTable {
    list-style: none;
    padding-left: 2em;
    padding-right: 2em;
    text-align: center;

    @media screen and (max-width: 768px) {
      padding-left: 0em;
      padding-right: 0em;
    }

    &_table {
      vertical-align: middle;
      
      background-color: $bgctables;
      width: calc(60% - 30px);
      display: inline-block;
      padding: 0px 30px 40px;
      text-align: center;      
      transition: all 0.3s ease;
      border-radius: 5px;
      margin: 0 15px;
      @media screen and (max-width: 991px) {
        width: calc(100% - 30px);
      }

      @media screen and (max-width: 768px) {
        width: calc(100% - 30px);
        margin-bottom: 30px;
        max-width: 520px;
        padding: 0px 20px 40px;
      }
      @media screen and (max-width: 480px) {
        width: calc(100% - 30px);
        margin-bottom: 30px;
        max-width: 420px;
      }
      &:hover {
        transform: scale(1.08);
      }

      @media screen and (max-width: 620px) {
        width: calc(100% - 30px);
        margin-bottom: 30px;

        // &:hover {
        //   transform: scale(1);
        // }
      }

      &__header {
        font-weight: normal;
        font-size: 14px;
        padding: 12px 60px;
        //border-bottom: 2px solid #444444;
        letter-spacing: 0.03em;

        @media screen and (max-width: 1068px) {
          font-size: 14px;
        }

        @media screen and (max-width: 768px) {
          padding: 12px 0px;
        }

      }

      &__pricing {
        font-size: 14px;
        line-height: 0.7;
        width: calc(25% - 8px);
        // border: 2px solid #ff02d06b;
        padding: 26px 10px 30px;
        margin: 4px;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s ease;
        transform: translateY(0);
        overflow: hidden;
        //font-family: 'Roboto, Times New Roman', Times, serif;

        @media screen and (max-width: 768px) {
          width: calc(33.33% - 8px);
        }



        // &:before {
        //   content: '';
        //   background-color: #ff03c1;
        //   font-size: 14px;
        //   padding: 2px;
        //   position: absolute;
        //   right: -18px;
        //   bottom: -18px;
        //   width: 36px;
        //   height: 36px;
        //   transform: rotate(45deg);
        //   opacity: 0;
        //   transition: 0.3s ease;
        // }

        // &:after {
        //   content: '✓';
        //   position: absolute;
        //   bottom: 3px;
        //   right: 3px;
        //   z-index: 2;
        //   font-size: 14px;
        //   opacity: 0;
        //   transition: 0.3s ease;
        // }

        //&:hover:before,
        //&:hover:after,
        &.active:before,
        &.active:after {
          opacity: 1;
        }

        del {
          display: block;
          padding-bottom: 10px;
          font-size: 14px;
          color:rgb(55, 0, 255);
        }
        del1 {
          display: block;
          font-size: 14px;
        }

        span:last-of-type {
          vertical-align: bottom;
          font-size: 1.25em;
          letter-spacing: 0.04em;
          display: block;
          padding-top: 6px;
        }
      }

      &__options {
        list-style: none;
        padding: 15px;
        font-size: 0.9em;
        border-bottom: 2px solid #444444;

        @media screen and (max-width: 1068px) {
          font-size: 0.85em;
        }

        @media screen and (max-width: 768px) {
          padding: 15px 0;
        }



        &>li {
          padding: 8px 0px 8px 30px;
          text-align: left;

          &:before {
            content: '✓';
            display: inline-flex;
            margin-right: -15px;
            color: white;
            background-color: #d70088;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            font-size: 0.8em;
            padding: 2px;
            align-items: center;
            justify-content: center;
            transform: translateX(-30px);

            @media screen and (max-width: 1068px) {
              width: 14px;
              height: 14px;
              padding: 1.5px;
            }

          }
        }
      }

      &__getstart {
        color: white;
        border: 0;
        background-color: #d60088;
        margin-top: 20px;
        border-radius: 8px;
        cursor: pointer;
        padding: 10px 30px;
        // box-shadow: 0px 3px 0px 0px #ff0a7a;
        letter-spacing: 0.07em;
        transition: all 0.4s ease;

        @media screen and (max-width: 1068px) {
          font-size: 0.95em;
        }

        // &:hover {
        //   transform: translateY(-10px);
        //   box-shadow: 0px 40px 29px -19px #ff0a7ae6;
        // }

        // &:active {
        //   box-shadow: inset 0 0 10px 1px rgba(102, 165, 100, 1), 0px 40px 29px -19px rgba(102, 172, 100, 0.95);
        //   transform: scale(0.95) translateY(-9px);
        // }
      }
    }


  }
}

.price-body {
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media screen and (max-width: 620px) {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}

.price {
  border-top: 2px solid #37102b;
  padding: 0px 0px;
  border-bottom: 2px solid #37102b;
  margin-top: 10px;
}

.toggle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 50px;

  @media screen and (max-width: 620px) {
    margin: 0 0 20px;
  }
}

.toggle input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 15px;
  background: grey;
  display: block;
  border-radius: 10px;
  position: relative;
  margin: 0 15px;
}

.toggle label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 9px;
  transition: 0.3s;
}

.toggle input:checked+label {
  background: #ff03c1;
}

.toggle input:checked+label:after {
  left: calc(100% - 0px);
  transform: translateX(-100%);
}

.toggle label:active:after {
  width: 20px;
}