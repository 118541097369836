.video-plyr{
    height: 90vh;
    overflow: hidden;
    position: relative;
    z-index: 4;
    width: 100%;
    .plyr{
        margin: auto;
    }
}
.close-icon-video {
    filter: invert(1);
    position: absolute;
    top: 2rem;
    right: 2rem;
    min-width: auto;
    line-height: normal;
    border-radius: 50%;
    padding: 8px;
    transition: .4s ease-in-out;
    z-index: 9;
    transform: scale(0.65);
    cursor: pointer;
    width: 70px;
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media (max-width: 1280px) {
        top: 0;
        right: 0;
        transform: scale(0.5);
    }
    &:hover {
        background: #00000038;
    }
}
.skip-intro {
    position: absolute;
    bottom: 20%;
    right: 3rem;
    color: #fff;
    z-index: 2;
    background: #ffffff36;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid #fff;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    @media (max-width: 1280px) {
        bottom: 22%;
        right: 1.5rem;
        font-size: 12px;
        padding: 3px 7px 2px;
    }
    &:hover {
        background: #ffffff5c;
        
    }
}
.video-titles {
    position: absolute;
    top: 2rem;
    left: 2rem;
    min-width: auto;
    line-height: normal;
    padding: 8px;
    transition: .4s ease-in-out;
    z-index: 9;
    transform: scale(0.65);
    @media (max-width: 1280px) {
        bottom: 22%;
        right: 1.5rem;
        font-size: 12px;
        padding: 3px 7px 2px;
    }
    h2 {
        font-size: 2rem !important;
    }
}
.plyr--hide-controls {
    .close-icon-video {
        top: 0;
        opacity: 0;
        pointer-events: none;
    }
    .video-titles {
        top: 0;
        opacity: 0;
        pointer-events: none;
    }
}