.acc-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #181818;
    padding: 1rem;
  }
  
  .acc-body-inner {
    width: 100%;
    max-width: 400px;
    text-align: center;
    background: #242424;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  
    &:hover {
      transform: scale(1.01);
    }
  
    h1 {
      font-size: 1.4rem;
      color: #ff0891;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  
    .profile-section {
      margin-bottom: 1.5rem;
    }
  
    img.profile-pic {
      width: 80px;
      height: 80px;
      background: #1f1f1f;
      border-radius: 50%;
      padding: 5px;
      margin-bottom: 1rem;
      border: 2px solid #ff0891;
    }
  
    h2 {
      font-size: 1.3rem;
      color: #ffffff;
      margin-bottom: 0.5rem;
    }
  
    p {
      font-size: 0.9rem;
      color: #bbbbbb;
      margin: 0.2rem 0;
    }
  
    .button {
      background-color: #ff0891;
      color: #ffffff;
      border: none;
      border-radius: 20px;
      padding: 0.6rem;
      margin-top: 0.8rem;
      width: 100%;
      font-size: 0.9rem;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease;
  
      &:hover {
        background-color: #e0077f;
        transform: translateY(-2px);
      }
    }
  
    .plan-section {
      background: #333333;
      padding: 1rem;
      border-radius: 8px;
      margin-top: 1rem;
      text-align: left;
  
      .heading {
        color: #ffffff;
        margin-bottom: 0.8rem;
        font-size: 0.9rem;
      }
  
      .plan-details {
        color: #ff0891;
        font-weight: bold;
      }
    }
  
    .subscribe-button {
      background-color: #007bff;
      &:hover {
        background-color: #0056b3;
      }
    }
  
    .logout-button {
      background-color: #dc3545;
      &:hover {
        background-color: #c82333;
      }
    }
  
    .delete-button {
      background-color: #ff3333;
      margin-top: 0.5rem;
      &:hover {
        background-color: #cc0000;
      }
    }
  }
  
  /* Modal for Delete Account */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .close-button {
    margin-top: 1rem;
    background-color: #333;
    color: #fff;
    &:hover {
      background-color: #555;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .acc-body-inner {
      padding: 1.2rem;
      max-width: 350px;
    }
  
    h1 {
      font-size: 1.2rem;
    }
  
    img.profile-pic {
      width: 60px;
      height: 60px;
    }
  
    h2 {
      font-size: 1.1rem;
    }
  
    p {
      font-size: 0.8rem;
    }
  
    .button {
      padding: 0.5rem;
      font-size: 0.8rem;
    }
  
    .plan-section {
      font-size: 0.8rem;
      padding: 0.8rem;
    }
  }
  