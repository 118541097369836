.login-main {
    .login-main-inner {
        padding-top: 3rem;
        min-width: 400px;
        padding: 0 30px;
        .App-logo {
            width: 200px;
            margin: 0 auto 20px;
            display: block;
        }
        h3 {
            font-size: 1.75rem;
            text-align: center;
            margin-bottom: 2.375rem;
            &.verify {
                margin-bottom: 1rem;
            }
        }
        .code-text {
            text-align: center;
            color: #bfbfbf;
            font-weight: 600;
            margin-bottom: 3rem;
            span {
                color: #1877f2;
                padding-left: 8px;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .button {
            &.ref {
                background: #ffffff14;
                border: 1px solid #313232;
                max-width: 400px;
                height: 40px;
                margin-bottom: 12px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                &:hover {
                    background: #ffffff33;
                }
                svg {
                    display: inline-flex;
                    position: absolute;
                    left: 10px;
                    right: auto;
                    &.gooogle {
                        height: 18px;
                        margin-right: 8px;
                        min-width: 18px;
                        width: 18px;
                    }
                }
            }
            &.verify {
                margin-top: 1rem;
            }
        }
        .break-or {
            position: relative;
            width: 100%;
            display: block;
            text-align: center;
            margin: 1.95rem 0 1.15rem;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                background: #313232;
                z-index: 1;
            }
            span {
                background: #000820;
                position: relative;
                z-index: 2;
                display: inline-block;
                padding: 0 1rem;
            }
        }
        form {
            .form-group {
                margin-bottom: 1rem;
                &.verify {
                    max-width: 100%;
                    margin-top: 1rem;
                }
                .input-label {
                    margin-bottom: 2px;
                }
                .login-input {
                    width: 100%;
                    height: 50px;
                    padding: 0 15px;
                    font-size: 18px;
                    -moz-appearance: textfield;
                    box-shadow: none;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: transparent;
                    border-radius: 4px;
                    border: 1px solid #313232;
                    outline: none;
                    color: #fff;
                    &:focus {
                        border: 1px solid #8ab4f8!important;
                    }
                    &.phone {
                        padding: 0 15px 0 60px;
                    }
                }
            }
            .button {
                width: 100%;
                height: 50px;
                font-size: 1rem;
            }
            .otp-input {
                margin-top: 1.5rem;
                margin-bottom: 1rem;
                .single-otp-input {
                    width: 50px;
                    height: 50px;
                    margin: 0 5px;
                    text-align: center;
                    font-size: 1.125rem;
                    background: transparent;
                    border-radius: 4px;
                    border: 1px solid #fff;
                    outline: none;
                    color: #fff;
                    @media (max-width: 991px) {
                        width: 45px;
                        height: 45px;
                    }
                    &:focus {
                        border: 1px solid #8ab4f8!important;
                    }
                }
            }
        }
        .code-counter {
            .link-header {
                color: #999595;
                .strong-clr {
                    font-weight: 700;
                    padding-left: 8px;
                }
                .change {
                    color: #1877f2;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

    }
}