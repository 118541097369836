.about-body {
    padding-top: 3rem;
    padding-bottom: 3rem;
    h1 {
        padding-bottom: 1.5rem;
    }
    p {
        margin-bottom: 1rem;
        color: #bfbfbf;
    }
    h3 {
        margin-bottom: 1rem;
    }
    a {
        color: #ff02ce;
        &:hover {
            text-decoration: underline;
        }
    }
    ul {
        margin-bottom: 1.5rem;
        li {
            &.terms {
                color: #8ab4f8;
                font-size: 1.125rem;
                margin-bottom: 0.5rem;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
}