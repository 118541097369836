.delete-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #1b1b1b; // Dark background
  color: #ffffff;
}

.delete-account-card {
  background: #2a2a2a;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.delete-account-container h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #ff4b4b;
}

.delete-account-container p {
    font-size: 1rem;
    line-height: 1.5;
}

.error-message {
  color: #ff4b4b;
  margin-bottom: 20px;
}

.delete-account-button,
.confirm-button,
.cancel-button {
  background: #ff4b4b;
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s;
}

.confirm-button {
  background: #ff6961;
}

.cancel-button {
  background: #ffffff;
  color: #2a2a2a;
}

/* Adding margin between the buttons */
.confirm-button,
.cancel-button {
  margin-right: 10px;
}

.confirm-button:last-child,
.cancel-button:last-child {
  margin-right: 0;
}

.confirm-button:hover,
.cancel-button:hover,
.delete-account-button:hover {
  background: #ff3232;
}
