.footer {
    border-top: 1px solid #121212;
    padding: 3rem 5rem;
    margin-top: 1rem;

    @media (max-width: 1280px) {
        padding: 3rem 2.5rem;
    }

    @media (max-width: 1100px) {
        padding: 3rem 2.5rem;
    }

    @media (max-width: 768px) {
        padding: 3rem 25px;
    }

    h4 {
        font-size: 14px;
        margin-bottom: 0.625rem;
        font-weight: 500;
        color: #ff03c1;
        margin-top: 1.0rem;
    }

    .footer-link {
        @media (max-width: 1100px) {
            width: 100% !important;
            text-align: center !important;
            margin-bottom: 30px;
        }

        &.main-desc {
            max-width: 300px;

            @media (max-width: 1100px) {
                max-width: 100%;
            }

            img {
                margin-bottom: 1.175rem;
                width: 150px;
                height: inherit;
            }

            p {
                color: #bfbfbf;
                line-height: 30px;
                font-size: 14px;
                margin-bottom: 30px;

                @media (max-width: 1100px) {
                    line-height: 150%;
                    margin: 0 auto 12px;
                    max-width: 300px;
                }
            }

            span {
                color: #bfbfbf;
                font-size: 12px;
            }
        }

        &.menu {
            @media (max-width: 1440px) {
                // width: 25%;
            }

            .footer-menus {
                .footer-menu {
                    padding: 0px;
                    margin-bottom: 12px;
                    @media (max-width: 1100px) {
                        margin-bottom: 12px;
                    }

                    @media (max-width: 480px) {
                        width: 100%;
                    }

                    a {
                        color: #bfbfbf;
                        font-size: 14px;
                        transition: 0.3s ease;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }

            span {
                color: #bfbfbf;
                font-size: 14px;

                @media (max-width: 1440px) {
                    width: 100%;
                }
            }

            small {
                font-size: inherit;

                @media (max-width: 480px) {
                    display: none;
                }
            }
        }

        &.app {
            @media (max-width: 1440px) {
                // width: 25%;
            }

            .download {
                @media (max-width: 1100px) {
                    justify-content: center;
                }

                li {
                    &:first-child {
                        margin-right: 12px;
                    }

                    img {
                        border: 1px solid #202020;
                        border-radius: 8px;
                    }
                }
            }
        }

        &.social {
            @media (max-width: 1440px) {
                // width: 25%;
            }
            .footer-menus {
                .footer-menu {
                    // padding: 0 10px;
                    margin-bottom: 12px;
                    @media (max-width: 1100px) {
                        margin-bottom: 12px;
                    }

                    @media (max-width: 480px) {
                        width: 100%;
                    }

                    a {
                        color: #bfbfbf;
                        font-size: 14px;
                        transition: 0.3s ease;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
            .connect {
                @media (max-width: 1100px) {
                    justify-content: center;
                }

                li {
                    margin-right: 12px;
                    filter: grayscale(1);
                    transition: 0.3s ease;

                    &:hover {
                        filter: none;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.footer-logo {
    width: 100%;
    margin-bottom: 15px;
}