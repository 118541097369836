.login-main {
    padding-top: 3rem;
}

.login-main .login-main-inner {
    padding-top: 3rem;
    min-width: 400px;
    padding: 0 30px;
}

.login-main .login-main-inner .App-logo {
    width: 200px;
    margin: 0 auto 20px;
    display: block;
}

.login-main .login-main-inner h3 {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 2.375rem;
}

.login-main .login-main-inner h3.verify {
    margin-bottom: 1rem;
}

.login-main .login-main-inner .code-text {
    text-align: center;
    color: #bfbfbf;
    font-weight: 600;
    margin-bottom: 3rem;
}

.login-main .login-main-inner .code-text span {
    color: #1877f2;
    padding-left: 8px;
    cursor: pointer;
}

.login-main .login-main-inner .code-text span:hover {
    text-decoration: underline;
}

.login-main .login-main-inner .button.ref {
    background: #ffffff14;
    border: 1px solid #313232;
    max-width: 400px;
    height: 40px;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.login-main .login-main-inner .button.ref:hover {
    background: #ffffff33;
}

.login-main .login-main-inner .button.ref svg {
    display: inline-flex;
    position: absolute;
    left: 10px;
    right: auto;
}

.login-main .login-main-inner .button.ref svg.gooogle {
    height: 18px;
    margin-right: 8px;
    min-width: 18px;
    width: 18px;
}

.login-main .login-main-inner .button.verify {
    margin-top: 1rem;
}

.login-main .login-main-inner .break-or {
    position: relative;
    width: 100%;
    display: block;
    text-align: center;
    margin: 1.95rem 0 1.15rem;
}

.login-main .login-main-inner .break-or:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    background: #313232;
    z-index: 1;
}

.login-main .login-main-inner .break-or span {
    background: #000;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0 1rem;
}

.login-main .login-main-inner form .form-group {
    margin-bottom: 1rem;
}

.login-main .login-main-inner form .form-group.verify {
    max-width: 100%;
    margin-top: 1rem;
}

.login-main .login-main-inner form .form-group .input-label {
    margin-bottom: 2px;
}

.login-main .login-main-inner form .form-group .login-input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-size: 18px;
    -moz-appearance: textfield;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #313232;
    outline: none;
    color: #fff;
}

.login-main .login-main-inner form .form-group .login-input:focus {
    border: 1px solid #8ab4f8!important;
}

.login-main .login-main-inner form .form-group .login-input.phone {
    padding: 0 15px 0 60px;
}

.login-main .login-main-inner form .button {
    width: 100%;
    height: 50px;
    font-size: 1rem;
}

.login-main .login-main-inner form .otp-input {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.login-main .login-main-inner form .otp-input .single-otp-input {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    text-align: center;
    font-size: 1.125rem;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #232424;
    outline: none;
    color: #fff;
}

@media (max-width: 991px) {
    .login-main .login-main-inner form .otp-input .single-otp-input {
        width: 45px;
        height: 45px;
    }
}

.login-main .login-main-inner form .otp-input .single-otp-input:focus {
    border: 1px solid #8ab4f8!important;
}

.login-main .login-main-inner .code-counter .link-header {
    color: #999595;
}

.login-main .login-main-inner .code-counter .link-header .strong-clr {
    font-weight: 700;
    padding-left: 8px;
}

.login-main .login-main-inner .code-counter .link-header .change {
    color: #1877f2;
}

.login-main .login-main-inner .code-counter .link-header .change:hover {
    text-decoration: underline;
}
