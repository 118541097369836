.header {
    // position: absolute;
    margin-bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 172;
    padding: 11px 5rem;
    // background: #151515;
    transition: all .3s;
    // box-shadow: 0 3px 6px 0 #ffffff2e;
    @media (max-width: 1280px) {
        padding: 11px 2.5rem;
    }
    @media (max-width: 768px) {
        padding: 11px 25px;
    }
    .menu {
        margin-left: .5rem;
        font-size: 16px;
        position: relative;
        transition: .3s;
        color: #fff;
        cursor: pointer;
        font-weight: 500;
        &:hover {
            color: #ff03c1;
        }
        @media (max-width: 768px) {
            margin-left: 1.5rem;
            font-size: 14px;
        }
    }
}
.heder-search {
    position: relative;
    margin-right: 20px;
    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(1.3);
        left: 8px;
        fill: #fff;
        pointer-events: none;
        transition: 0.3s ease;
    }
    .header-search-input {
        outline: none;
        background: transparent;
        border-radius: 4px;
        height: 36px;
        color: #fff;
        width: 0;
        padding: 8px 0px 8px 30px;
        border: 0;
        transition: 0.3s ease;
        &::placeholder {
            color: #bfbfbf;
        }
        &:focus {
            width: 250px;
            padding: 8px 12px 8px 37px;
            border: 1px solid #a3a3a3;
            & + svg {
                transform: translateY(-50%);
            }
        }
        &:hover {
            & + svg {
                fill: #ff03c1;
            }
        }
    }
}
.mini-profile {
    &:hover {
        img {
            background: #726f6f;
        }
        .mini-arrow {
            transform: translateY(-50%) rotate(180deg);
        }
        .mini-profile-down {
            opacity: 1;
            padding-top: 0.5rem;
            pointer-events: auto;
        }
    }
    .App-profile {
        width: 36px;
        border-radius: 50%;
        background: #525252;
        padding: 0.25rem;
        transition: 0.3s ease;
    }
    .mini-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -1rem;
        transition: 0.3s ease;
    }
    .mini-profile-down {
        position: absolute;
        bottom: 0;
        z-index: 99;
        transform: translateY(100%);
        right: -30px;
        width: 16.75rem;
        padding-top: 1.5rem;
        transition: 0.3s ease;
        opacity: 0;
        pointer-events: none;
        .profile-down-inner {
            border-radius: 12px;
            background: #444;
            border: 1px solid #444;
        }
        .user-detail-list {
            padding: 20px;
            .App-profile {
                width: 3.25rem;
                height: 3.25rem;
                padding: 0.5rem;
            }
            .user-info{
                display: flex;
                width: calc(100% - 3.25rem);
                justify-content: space-between;
                .user-info-list {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: calc(100% - 35px);
                    .username {
                        margin-left: 0.75rem;
                        font-size: 1rem;
                        font-weight: 600;
                        line-height: normal;
                        margin-top: -0.5rem;
                    }
                    .user-no {
                        font-size: 12px;
                        margin-left: 0.75rem;
                        line-height: normal;
                        margin-bottom: 0;
                        opacity: .65;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-weight: 400;
                        .link {
                            color: #fff;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .account-details {
            border-top: 1px solid #545454;
            padding: 0 0 0.5rem;
            ul {
                li {
                    font-size: .875rem;
                    font-weight: 400;
                    border-bottom: 1px solid #545454;
                    &:last-child {
                        border-bottom: none;
                    }
                    a {
                        padding: 0.875rem 1.25rem;
                        color: #fff;
                        width: 100%;
                        transition: 0.3s ease;
                        font-weight: 600;
                        &:hover {
                            background: #9b9999;
                        }
                        svg {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
.App-logo {
    @media (max-width: 768px) {
        max-width: 100px;
    }
}
.header-notification {
    width: 36px;
    height: 36px;
    margin-right: 18px;
    &:hover {
        svg {
            fill: #ff03c1;
        }
    }
    svg {
        fill: #fff;
        transition: 0.3s ease;
        transform: scale(1.3);
    }
}