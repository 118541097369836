
.card-row {
    padding-bottom: 3rem;
    &:hover {
        .swiper-button-prev, .swiper-button-next {
            opacity: 1;
        }
    }
    .card-header {
        margin-bottom: 1.25rem;
        .card-arrow {
            transition: 0.3s ease;
            opacity: 0;
            .arrows {
                transform: scale(1.25);
                transition: 0.5s ease;
                &.prev-arrow {
                    margin-right: 1.25rem;
                    &:hover {
                        transform: scale(1.25) translateX(-10px);
                    }
                    svg {
                        transform: rotate(180deg);
                    }
                }
                &.next-arrow {
                    &:hover {
                        transform: scale(1.25) translateX(10px);
                    }
                }
                svg {
                    fill: #fff;
                }
            }
        }
    }
    .mySwiper {
        &.search {
            margin: -8px;
            .swiper-slide {
                width: calc(20% - 16px);
                margin: 8px;
                @media (max-width: 1366px) {
                    width: calc(25% - 16px);
                }
                @media (max-width: 991px) {
                    width: calc(33.33% - 16px);
                }
                @media (max-width: 680px) {
                    width: calc(50% - 16px);
                }
            }
        }
        .card-thumb {
            border-radius: 8px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            -o-object-fit: cover;
            object-fit: cover;
            transition: all .3s ease-out;
            -o-object-position: center;
            object-position: center;
            margin: 0!important;
        }
        .card-thumbV {
            border-radius: 8px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            -o-object-fit: scale-down;
            object-fit: scale-down;
            transition: all .3s ease-out;
            -o-object-position: center;
            object-position: center;
            margin: 0!important;
        }
        .continue-border {
            position: absolute;
            bottom: 0;
            height: 4px;
            background: #545454;
            left: 0;
            right: 0;
            z-index: 10;
            border-radius: 4px;
            span {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background: #ff03c1;
                z-index: 12;
                border-radius: 4px;
            }
        }
        .card-thumb-top {
            overflow: hidden;
            border-radius: 8px;
            padding: 49.5% 1.25rem 1.25rem;
            position: relative;
            width: 100%;
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 35%;
                z-index: 8;
                // background: linear-gradient(180deg,transparent,#000)!important;
                animation-name: fadeIn;
                animation-duration: 10s;
                animation-fill-mode: forwards;
                pointer-events: none;
            }
            &:hover {
                .play-icon {
                    fill: #fff;
                }
            }
            .play-icon {
                fill: #bfbfbf;
                position: absolute;
                bottom: 0.7rem;
                right: 0.2rem;
                z-index: 9;
                &.left {
                    left: 0.2rem;
                }
            }
        }
        .card-thumb-topV {
            overflow: hidden;
            border-radius: 8px;
            padding: 130.5% 1.25rem 1.25rem;
            position: relative;
            width: 100%;
            // @media (max-width: 768px) {
            //     padding: 90.5% 0.15rem 0.15rem;
            // }
        
            // @media (max-width: 600px) {
            //     padding: 90.5% 0.15rem 0.15rem;
            // }
        
            // @media (max-width: 500px) {
            //     padding: 90.5% 0.15rem 0.15rem;
            // }
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 35%;
                z-index: 8;
                // background: linear-gradient(180deg,transparent,#000)!important;
                animation-name: fadeIn;
                animation-duration: 10s;
                animation-fill-mode: forwards;
                pointer-events: none;
            }
            &:hover {
                .play-icon {
                    fill: #fff;
                }
            }
            .play-icon {
                fill: #bfbfbf;
                position: absolute;
                bottom: 0.7rem;
                right: 0.2rem;
                z-index: 9;
                &.left {
                    left: 0.2rem;
                }
            }
        }
        .card-title {
            font-weight: 400;
            font-size: 14px;
            padding: 0.5rem 1.25rem 0 0;
            color: #fff;
            transition: 0.3s ease;
           
              @media (max-width: 768px) {
                font-size: .9rem;
              }
            
              @media (max-width: 600px) {
                font-size: .9rem;
              }
            
              @media (max-width: 500px) {
                font-size: .9rem;
              }
            &:hover {
                color: #ff03c1;
            }
            &.chapter-title {
                margin-bottom: 4px;
                font-size: 16px;
                color: #fff;
                padding: 0.5rem 3.25rem 0 0;
            }
        }
        .badges {
            margin-bottom: 0.5rem;
            transform: scale(0.8);
            transform-origin: top left;
        }
        .chapter-desc {
            color: #bfbfbf;
            font-size: 13px;
        }
        .share-icon {
            position: absolute;
            top: 1.25rem;
            right: 8px;
            transform: translateY(-50%);
            fill: #bfbfbf;
            &:hover {
                fill: #fff;
            }
        }
    }
    .swiper-button-disabled {
        display: none;
    }
    .swiper-button-prev, .swiper-button-next {
        height: 100%;
        transform: none;
        top: 5px;
        width: 50px;
        color: #fff;
        background: rgb(0,0,0);
        opacity: 0;
        transition: 0.3s ease;
        @media (max-width: 680px) {
            opacity: 1;
        }
        &:after {
            @media (max-width: 680px) {
                font-size: 30px;
            }
        }
    }
    .swiper-button-prev {
        left: 0;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6278886554621849) 49%, rgba(0,212,255,0) 100%);
    }
    .swiper-button-next {
        right: 0;
        background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6278886554621849) 49%, rgba(0,212,255,0) 100%);
    }
}