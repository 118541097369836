*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus,
::after:focus,
::before:focus {
  outline: none;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.inherit-height {
  height: inherit;
}

.block {
  display: block;
}

.none {
  display: none;
}

.inline-block {
  display: inline-block;
}

.relative {
  position: relative;
}

.flex {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.cursor {
  cursor: pointer;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.filter-invert {
  filter: invert(1);
}

.text-control {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-control-two,
.text-control-three {
  white-space: break-spaces;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-control-two {
  -webkit-line-clamp: 2;
}

.text-control-three {
  -webkit-line-clamp: 3;
}

.pointer-none {
  pointer-events: none;
}

html {
  font-size: 18px;
}

@media all and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}

@media all and (max-width: 1680px) {
  html {
    font-size: 15px;
  }
}

@media all and (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

@media all and (max-width: 580px) {
  html {
    font-size: 13px;
  }
}

body {
  font-family: 'sans-serif', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  min-height: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background-color: #151515;
  color: #fff;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

h1 {
  font-size: 1.75rem;
  line-height: 1.286;
}

h1.lg {
  font-size: 2.5rem;
  line-height: 1.2;
}

h1.sm {
  font-size: 2rem;
  line-height: 1.125;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.33;

  @media (max-width: 991px) {
    font-size: 1.0rem;
  }

  @media (max-width: 768px) {
    font-size: 1.0rem;
  }

  @media (max-width: 600px) {
    font-size: 1.0rem;
  }

  @media (max-width: 500px) {
    font-size: 1.0rem;
  }
}

h3 {
  font-size: 1.25rem;
  line-height: 1.33;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.45;
  @media (max-width: 991px) {
    font-size: .9rem;
  }

  @media (max-width: 768px) {
    font-size: .9rem;
  }

  @media (max-width: 600px) {
    font-size: .9rem;
  }

  @media (max-width: 500px) {
    font-size: .9rem;
  }
}

h5 {
  font-size: 0.875rem;
  line-height: 1.57;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

p.mini-lh {
  line-height: 1.3;
}

p.smini-lh {
  line-height: 1.25;
}

p.vsmini-lh {
  line-height: 1.125;
}

span.normal {
  font-size: 0.875rem;
  line-height: 1.5;
}

span.small {
  font-size: 0.75rem;
  line-height: 1.5;
}

span.mini-lh {
  line-height: 1.3;
}

span.smini-lh {
  line-height: 1.25;
}

span.vsmini-lh {
  line-height: 1.125;
}

.button {
  padding: 8px 16px 10px;
  border: 1px solid #d9082d;
  background: #ff097c;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 300;
  color: #fff;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s ease;

  a {
    color: #fff;
  }
}

.button:hover {
  background: #ff03c1 //#ff0a7c;
}

.main-body {
  padding: 0 5rem;

  @media (max-width: 1280px) {
    padding: 0 2.5rem;
  }

  @media (max-width: 768px) {
    padding: 0 25px;
  }
}

// Common Css Start
.badges {
  margin-bottom: 1.875rem;

  .badge {
    padding: 0.05rem 0.5rem 0.01rem;
    border-radius: 2px;
    margin-right: 0.5rem;
    font-size: .875rem;
    background: #656565;
    color: #D5D5D5;
  }
}

.react-tel-input .flag-dropdown {
  background-color: #161616 !important;
  border: 1px solid #313232 !important;

  &.open {
    background: #161616 !important;

    .selected-flag {
      background: transparent !important;
    }
  }

  .selected-flag {
    width: 50px;
    padding: 0 0 0 13px;

    &:focus,
    &:hover {
      background-color: #ffffff14;
    }
  }
}


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 99999;
}

.skeleton-animation {
  background: #313131;
}

.skeleton-title {
  width: 200px;
  height: 10px;
  border-radius: 4px;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

// Common Css End


.app-link {
  align-items: center;
  text-align: center;
  margin-top: 20%;
}

.app-logo {
  margin-bottom: 16px;
}

.action-container {
  margin: 20px;
}

.action {
  border: 0px;
  padding: 0px;
  margin: 0px;
  background: #000820;
}