.detail {
    padding-top: 4rem;
    padding-bottom: 4rem;
    .detail-top {
        align-items: flex-start;
        padding-bottom: 3rem;
        .detail-top-left {
            padding-right: 2.5rem;
            width: calc(100% - 45rem);
            @media (max-width: 1440px) {
                width: calc(100% - 40rem);
            }
            @media (max-width: 1280px) {
                width: calc(100% - 30rem);
            }
            @media (max-width: 991px) {
                width: 100%;
                padding: 30px 0 0;
                order: 2;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
            h1{
                font-size: 2.25rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            p {
                font-size: 1rem;
                color: #bfbfbf;
                padding-bottom: 1.875rem;
                letter-spacing: .32px;
            }
            .cta {
                margin-bottom: 1.25rem;
                .button {
                    font-size: 1.25rem;
                    min-height: 3.375rem;
                    letter-spacing: .4px;
                    padding: 0.4rem 2rem 0.4rem 2rem;
                    @media (max-width: 991px) {
                        padding: 0rem 1rem 0rem 0rem;
                        font-size: 1rem;
                    }
                    &:first-child {
                        margin-right: 1.25rem;
                    }
                    svg {
                        margin-right: 4px;
                        transform: translateY(-1px);
                        @media (max-width: 991px) {
                            margin-right: 0;
                            transform: translateY(-1px) scale(0.6);
                        }
                    }
                }
                .continue-border {
                    position: absolute;
                    bottom: 0;
                    height: 4px;
                    background: #ffffff82;
                    left: 0;
                    right: 0;
                    z-index: 10;
                    border-radius: 4px;
                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        background: #ffffff;
                        z-index: 12;
                        border-radius: 4px;
                    }
                }
            }
            .share-button {
                padding-top: 10px;
                li {
                    margin-right: 2.5rem;
                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }
                    &.add {
                        svg {
                            transform: scale(1.7);
                        }
                    }
                    sup {
                        transform: translateY(-0.75rem);
                    }
                    span {
                        font-size: .75rem;
                        color: #bfbfbf;
                        margin-top: 0.6rem;
                        display: block;
                    }
                    svg {
                        transform: scale(1.4);
                    }
                }
            }
        }
        .detail-top-right {
            width: 45rem;
            box-shadow: 0 6px 30px #5e5e5e61;
            border-radius: 12px;
            position: relative;
            overflow: hidden;
            @media (max-width: 1440px) {
                width: 40rem;
            }
            @media (max-width: 1280px) {
                width: 30rem;
            }
            @media (max-width: 991px) {
                width: 100%;
                order: 1;
            }
        }
    }
    .detail-bottom {
        padding-top: 3rem;
        .about-detail {
            border-bottom: 1px solid #121212;
            padding-bottom: 3rem;
            margin-bottom: 3rem;
            h1 {
                font-size: 1.875rem;
                font-weight: 600;
            }
            h2 {
               font-weight: 400;
               margin: 20px 0 6px;
            }
            .que {
                font-weight: 600;
                color: #9f9f9f;
                width: 150px;
            }
            .det {
                color: #fff;
                font-weight: 400;
                font-style: italic;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 500px;
                &.full {
                    width: inherit;
                }
            }
            .more {
                color: #ff03c1;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
            ul li {
                padding-bottom: 5px;
            }
            .tags {
                margin-bottom: 1rem;
                color: #1877f2;
            }
            p {
                font-size: 1rem;
                letter-spacing: .32px;
                color: #bfbfbf;
            }
        }
    }
    .detail-menu {
        border-bottom: 1px solid #121212;
        position: sticky;
        top: 58px;
        z-index: 99;
        background: black;
        padding-top: 8px;
        ul {
            li {
                margin-right: 1rem;
                &:hover, &.active {
                    color: #ff03c1;
                    &:before {
                        opacity: 1;
                        transition: .3s linear;
                        width: 100%;
                        left: 0;
                    }
                }
                &:before {
                    content: "";
                    width: 0;
                    height: 3px;
                    background: #ff03c1;
                    bottom: 0;
                    left: 50%;
                    position: absolute;
                    transition-duration: .4s;
                    opacity: 0;
                }
                span {
                    padding: 0.75rem 1rem;
                    border-radius: 0.25rem 0.25rem 0 0;
                }
            }
        }
    }
}
select {
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    box-shadow:none;
    border:0!important;
    background: #1c1c1c;
    background-image: none;
    flex: 1;
    padding: 0 1em;
    color:#fff;
    cursor:pointer;
    font-size: 1em;
 }
 select::-ms-expand {
    display: none;
 }
 .select {
    position: relative;
    display: flex;
    width: 15em;
    height: 3em;
    line-height: 3;
    background: #5c6664;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 1rem;
 }
 .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: #363636;
    cursor:pointer;
    pointer-events:none;
    transition:.25s all ease;
 }
 .select:hover::after {
    color: #ff03c1;
 }

 .none {
    display: none !important;
 }