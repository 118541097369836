.banner {
    margin-bottom: 3.75rem;
    @media (max-width: 768px) {
        margin-bottom: 1.75rem;
    }

    @media (max-width: 600px) {
        margin-bottom: 1.75rem;
    }

    @media (max-width: 500px) {
        margin-bottom: 1.75rem;
    }
}

.banner-description {
    display: block;

    @media (max-width: 768px) {
        display: none;
    }
}

.bannerimage {
    border-top: 1px solid #121212;
    width: 100%;

    // height: 400px;   
    @media (max-width: 1280px) {
        // height: 400px
    }

    @media (max-width: 1100px) {
        // height: 350px
    }

    @media (max-width: 768px) {
        // height: 100%
    }
}

.zbannerimage {
    border-top: 1px solid #121212;
    width: 100%;
    height: 653px;

    @media (max-width: 1280px) {
        height: 653px
    }

    @media (max-width: 1100px) {
        height: 653px
    }

    @media (max-width: 768px) {
        height: 100%
    }
}

.buttonBanner {
    padding-right: 15px; // padding: 8px 16px 10px;
    border: 1px solid #d9082d;
    background: #ff097c;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s ease;

    a {
        color: #fff;
    }
}

.buttonBanner:hover {
    background: #ff03c1 //#ff0a7c;
}

.bnrdetail {
    padding-top: 4rem;
    padding-bottom: 4rem;
    width: 100%;

    @media (max-width: 1440px) {
        top: 3%;
    }

    @media (max-width: 1280px) {
        top: 1%;
    }

    @media (max-width: 991px) {
        top: 0%;
    }

    @media (max-width: 768px) {
        display: none;
    }

    @media (max-width: 600px) {
        display: none;
    }

    @media (max-width: 500px) {
        display: none;
    }

    .bnrdetail-top {
        align-items: flex-start;
        padding-bottom: 3rem;

        .bnrdetail-top-left {
            padding-left: 2.5rem;
            width: 50%;

            @media (max-width: 1440px) {
                width: 50%;
            }

            @media (max-width: 1280px) {
                width: 60%;
            }

            @media (max-width: 991px) {
                width: 80%;
                padding: 0rem 1rem 0rem 1rem;
                order: 2;
            }

            @media (max-width: 768px) {
                width: 100%;
                padding: 30px 0 0;
            }

            h2 {
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 1.3%;
                text-overflow: ellipsis;
                max-height: 1.7rem;
                overflow: hidden;
                overflow-wrap: break-word;
                white-space: pre-wrap;

                @media (max-width: 991px) {
                    display: none
                }

                @media (max-width: 768px) {
                    //margin-bottom: .7rem;
                    display: none
                }

                @media (max-width: 500px) {
                    //margin-bottom: .3rem;
                    display: none
                }

                @media (max-width: 400px) {
                    //margin-bottom: .1rem;
                    display: none
                }
            }

            p {
                font-size: 1rem;
                color: #bfbfbf;
                margin-bottom: 1.3%;
                letter-spacing: .32px;

                @media (max-width: 768px) {
                    //margin-bottom: .7rem;
                }

                @media (max-width: 500px) {
                    //margin-bottom: .5rem;
                }

                @media (max-width: 400px) {
                    //margin-bottom: .1rem;
                }
            }

            .desc {
                text-overflow: ellipsis;
                overflow: hidden;
                overflow-wrap: break-word;
                white-space: pre-wrap;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                @media (max-width: 1280px) {

                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                @media (max-width: 1100px) {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                @media (max-width: 991px) {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                @media (max-width: 768px) {
                    display: none
                }

                @media (max-width: 600px) {
                    display: none
                }

            }

            .cta {
                //margin-bottom: 1.25rem;
                margin-top: 20px;

                @media (max-width: 768px) {
                    margin-top: 0px;
                }

                .button {
                    font-size: 1.15rem;
                    letter-spacing: .4px;
                    // padding: 0.4rem 2rem 0.4rem 2rem;

                    @media (max-width: 991px) {
                        // padding: 0rem 1rem 0rem 1rem;
                        font-size: 1rem;
                        // min-height: 3%;
                    }

                    @media (max-width: 768px) {
                        padding: 0rem 1rem 0rem 1rem;
                        font-size: 1rem;
                        margin-top: 0px;
                        min-height: 2.075rem;
                    }

                    @media (max-width: 400px) {
                        min-height: 2.375rem;
                    }

                    &:first-child {
                        margin-right: 1.25rem;
                    }

                    svg {
                        margin-right: 4px;
                        transform: translateY(-1px);

                        @media (max-width: 991px) {
                            margin-right: 0;
                            transform: translateY(-1px) scale(0.6);
                        }
                    }
                }

                .continue-border {
                    position: absolute;
                    bottom: 0;
                    height: 4px;
                    background: #ffffff82;
                    left: 0;
                    right: 0;
                    z-index: 10;
                    border-radius: 4px;

                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        background: #ffffff;
                        z-index: 12;
                        border-radius: 4px;
                    }
                }
            }

            .share-button {
                padding-top: 10px;

                li {
                    margin-right: 2.5rem;

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }

                    &.add {
                        svg {
                            transform: scale(1.7);
                        }
                    }

                    sup {
                        transform: translateY(-0.75rem);
                    }

                    span {
                        font-size: .75rem;
                        color: #bfbfbf;
                        margin-top: 0.6rem;
                        display: block;
                    }

                    svg {
                        transform: scale(1.4);
                    }
                }
            }
        }

        .bnrdetail-top-right {
            width: 255rem;
            box-shadow: 0 6px 30px #5e5e5e61;
            border-radius: 12px;
            position: relative;
            overflow: hidden;

            @media (max-width: 1440px) {
                width: 255rem;
            }

            @media (max-width: 1280px) {
                width: 130rem;
            }

            @media (max-width: 991px) {
                width: 100%;
                order: 1;
            }
        }
    }

    .bnrdetail-bottom {
        padding-top: 3rem;

        .about-detail {
            border-bottom: 1px solid #121212;
            padding-bottom: 3rem;
            margin-bottom: 3rem;

            h1 {
                font-size: 1.875rem;
                font-weight: 600;
            }

            h2 {
                font-weight: 400;
                margin: 20px 0 6px;
            }

            .que {
                font-weight: 600;
                color: #9f9f9f;
                width: 150px;
            }

            .det {
                color: #fff;
                font-weight: 400;
                font-style: italic;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 500px;

                &.full {
                    width: inherit;
                }
            }

            .more {
                color: #ff03c1;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            ul li {
                padding-bottom: 5px;
            }

            .tags {
                margin-bottom: 1rem;
                color: #1877f2;
            }

            p {
                font-size: 1rem;
                letter-spacing: .32px;
                color: #bfbfbf;
            }
        }
    }

}

.bnrdetail-small {
    width: 100%;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }

    @media (max-width: 600px) {
        display: block;
    }

    @media (max-width: 500px) {
        display: block;
    }

    .bnrdetail-small-top {
        align-items: flex-start;

        .bnrdetail-small-top-left {
            width: 100%;

            h2 {
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 1.3%;
                text-overflow: ellipsis;
                max-height: 1.7rem;
                overflow: hidden;
                overflow-wrap: break-word;
                white-space: pre-wrap;

                @media (max-width: 991px) {
                    display: none
                }

                @media (max-width: 768px) {
                    //margin-bottom: .7rem;
                    display: none
                }

                @media (max-width: 500px) {
                    //margin-bottom: .3rem;
                    display: none
                }

                @media (max-width: 400px) {
                    //margin-bottom: .1rem;
                    display: none
                }
            }

            p {
                font-size: 1rem;
                color: #bfbfbf;
                margin-bottom: 1.3%;
                letter-spacing: .32px;

                @media (max-width: 768px) {
                    //margin-bottom: .7rem;
                }

                @media (max-width: 500px) {
                    //margin-bottom: .5rem;
                }

                @media (max-width: 400px) {
                    //margin-bottom: .1rem;
                }
            }

            .desc {
                text-overflow: ellipsis;
                overflow: hidden;
                overflow-wrap: break-word;
                white-space: pre-wrap;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                @media (max-width: 1280px) {

                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                @media (max-width: 1100px) {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                @media (max-width: 991px) {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                @media (max-width: 768px) {
                    display: none
                }

                @media (max-width: 600px) {
                    display: none
                }

            }

            .cta {
                //margin-bottom: 1.25rem;
  

                @media (max-width: 768px) {
                    margin-top: 0px;
                }

                .button {
                    font-size: 1.15rem;
                    letter-spacing: .4px;
                    margin-left: auto;
                    margin-right: auto;
                    // padding: 0.4rem 2rem 0.4rem 2rem;

                    @media (max-width: 991px) {
                        // padding: 0rem 1rem 0rem 1rem;
                        font-size: 1rem;
                        // min-height: 3%;
                    }

                    @media (max-width: 768px) {
                        padding: 0rem 1rem 0rem 1rem;
                        font-size: 1rem;
                        margin-top: 0px;
                        min-height: 2.075rem;
                    }

                    @media (max-width: 400px) {
                        min-height: 2.375rem;
                    }

                    // &:first-child {
                    //     margin-right: 1.25rem;
                    // }

                    svg {
                        margin-right: 4px;
                        transform: translateY(-1px);

                        @media (max-width: 991px) {
                            margin-right: 0;
                            transform: translateY(-1px) scale(0.6);
                        }
                    }
                }

                .continue-border {
                    position: absolute;
                    bottom: 0;
                    height: 4px;
                    background: #ffffff82;
                    left: 0;
                    right: 0;
                    z-index: 10;
                    border-radius: 4px;

                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        background: #ffffff;
                        z-index: 12;
                        border-radius: 4px;
                    }
                }
            }

            .share-button {
                padding-top: 10px;

                li {
                    margin-right: 2.5rem;

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }

                    &.add {
                        svg {
                            transform: scale(1.7);
                        }
                    }

                    sup {
                        transform: translateY(-0.75rem);
                    }

                    span {
                        font-size: .75rem;
                        color: #bfbfbf;
                        margin-top: 0.6rem;
                        display: block;
                    }

                    svg {
                        transform: scale(1.4);
                    }
                }
            }
        }

        .bnrdetail-small-top-right {
            display: none
        }
    }

    .bnrdetail-small-bottom {
        padding-top: 3rem;

        .about-detail {
            border-bottom: 1px solid #121212;
            padding-bottom: 3rem;
            margin-bottom: 3rem;

            h1 {
                font-size: 1.875rem;
                font-weight: 600;
            }

            h2 {
                font-weight: 400;
                margin: 20px 0 6px;
            }

            .que {
                font-weight: 600;
                color: #9f9f9f;
                width: 150px;
            }

            .det {
                color: #fff;
                font-weight: 400;
                font-style: italic;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 500px;

                &.full {
                    width: inherit;
                }
            }

            .more {
                color: #ff03c1;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            ul li {
                padding-bottom: 5px;
            }

            .tags {
                margin-bottom: 1rem;
                color: #1877f2;
            }

            p {
                font-size: 1rem;
                letter-spacing: .32px;
                color: #bfbfbf;
            }
        }
    }

}