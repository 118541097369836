.mySwiper {
    width: auto;
    .swiper-pagination-bullet {
        width: 16px;
        height: 4px;
        background-color: #fff;
        @media (max-width: 768px) {
            display: none
        }
    
        @media (max-width: 600px) {
            display: none
        }
    
        @media (max-width: 500px) {
            display: none
        }
    }
    .swiper-pagination-bullet-active {
        background: #ff03c1;
    }

   
}